<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="correctInvoiceForm"
        ref="correctInvoiceForm"
        class="custom-form pt-8"
        @submit.prevent="sumbitCorrectionForm"
      >
        <InvoicePreview :preview="preview">
          <v-row class="mt-4">
            <v-col cols="4">
              <v-menu
                v-model="showSaleDatePicker"
                v-bind="attributes.menu"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(invoiceData.saleDate)"
                    :rules="showSaleDatePicker ? [rules.required] : []"
                    label="Data sprzedaży"
                    placeholder="Wybierz datę sprzedaży"
                    v-bind="{ ...attrs, ...attributes.textField }"
                    v-on="on"
                  >
                    <template #prepend-inner>
                      <Icon
                        name="date"
                        size="small"
                        class="mr-2"
                      />
                    </template>
                    <template #append>
                      <SwitchDayButtons
                        v-model="invoiceData.saleDate"
                        :min="latestInvoice.saleDate"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="invoiceData.saleDate"
                  v-bind="attributes.datePicker"
                  :min="latestInvoice.saleDate"
                  :rules="showSaleDatePicker ? [] : [rules.required]"
                  @input="showSaleDatePicker = false"
                />
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-menu
                v-model="showIssueDatePicker"
                v-bind="attributes.menu"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(invoiceData.issueDate)"
                    :rules="showIssueDatePicker ? [] : [rules.required]"
                    label="Data wystawienia"
                    placeholder="Wybierz datę wystawienia"
                    v-bind="{ ...attrs, ...attributes.textField }"
                    v-on="on"
                  >
                    <template #prepend-inner>
                      <Icon
                        name="date"
                        size="small"
                        class="mr-2"
                      />
                    </template>
                    <template #append>
                      <SwitchDayButtons
                        v-model="invoiceData.issueDate"
                        @input="previewCorrectionOnIssueDateChange"
                        :min="latestInvoice.issueDate"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="invoiceData.issueDate"
                  v-bind="attributes.datePicker"
                  :min="latestInvoice.issueDate"
                  :rules="showIssueDatePicker ? [] : [rules.required]"
                  @input="previewCorrectionOnIssueDateChange"
                />
              </v-menu>
            </v-col>

            <v-col cols="4">
              <v-menu
                v-model="showDueDatePicker"
                v-bind="attributes.menu"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="paymentDueDaysString"
                    :rules="showDueDatePicker ? [] : [rules.required]"
                    label="Termin płatności"
                    placeholder="Wybierz termin płatności"
                    v-bind="{ ...attrs, ...attributes.textField }"
                    v-on="on"
                  >
                    <template #prepend-inner>
                      <Icon
                        name="date"
                        size="small"
                        class="mr-2"
                      />
                    </template>
                    <template #append>
                      <SwitchDayButtons
                        v-model="invoiceData.paymentDueDate"
                        :min="paymentMinDate"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="invoiceData.paymentDueDate"
                  v-bind="attributes.datePicker"
                  :rules="showDueDatePicker ? [] : [rules.required]"
                  :min="paymentMinDate"
                  @input="showDueDatePicker = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex">
                <v-autocomplete
                  :key="forceInvoiceNumberRefreshKey"
                  :value="invoiceData.invoiceNumber"
                  :items="availableInvoiceNumbers"
                  :label="availableInvoiceNumbers.length > 1 ? 'Dostępne numery dokumentu' : 'Nr dokumentu'"
                  placeholder="Wybierz nr dokumentu"
                  outlined
                  dense
                  :disabled="availableInvoiceNumbers.length < 2"
                  @input="updateInvoiceNumber"
                />
                <v-btn
                  class="ml-2"
                  icon
                  medium
                  color="primary"
                  target="_blank"
                  @click="refreshAvailableInvoiceNumbers"
                >
                  <v-icon
                    medium
                    class="rotate"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="isCorrectionTypeWithInvoiceItems ? 11 : 12">
              <v-autocomplete
                v-model="correctionType"
                :items="correctionTypes"
                :disabled="hasInvoiceDataAlreadyBeenChanged"
                outlined
                label="Typ korekty"
                dense
                @input="previewCorrection({ useInitialInvoiceItems: true })"
              />
            </v-col>
            <v-col
              v-if="isCorrectionTypeWithInvoiceItems"
              cols="1"
            >
              <v-btn
                icon
                color="primary"
                title="Przywróć stan początkowy"
                @click="previewCorrection({ useInitialInvoiceItems: true })"
              >
                <v-icon>mdi-restore</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="correctionType === 'Dane'">
            <v-col cols="12">
              <v-text-field
                outlined
                label="Nabywca"
                v-model="invoiceData.buyerName"
                :rules="[rules.required]"
                placeholder="Wpisz nazwę nabywcy"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                outlined
                label="Adres"
                v-model="invoiceData.address"
                validate-on-blur
                :rules="[rules.required]"
                placeholder="Wpisz ulicę i nr lokalu"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                outlined
                label="Nr NIP"
                v-model="invoiceData.nip"
                :rules="[rules.required, rules.nipNumber]"
                placeholder="Wpisz numer NIP"
                validate-on-blur
                v-mask="'##########'"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                outlined
                label="Kod pocztowy"
                v-model="invoiceData.postalCode"
                :rules="[rules.required, rules.postCode]"
                validate-on-blur
                placeholder="xx-xxx"
                v-mask="getFieldMask('postCode')"
              />
            </v-col>
            <v-col cols="8">
              <v-text-field
                outlined
                label="Miejscowość"
                v-model="invoiceData.city"
                :rules="[rules.required]"
                placeholder="Wpisz nazwę miejscowości"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="invoiceData.notes"
                label="Uwagi"
                rows="2"
                placeholder="Uwagi dotyczące dokumentu"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <template v-else-if="invoiceItems.length">
            <v-row
              v-for="(invoiceItem, index) in invoiceItems"
              :key="`${invoiceItem.id} ${invoiceItem.splittedTime}`"
            >
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      label="Nazwa usługi"
                      class="invoice-item-name"
                      :value="invoiceItem.name"
                      hide-details
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model.number="invoiceItem.netValue"
                      outlined
                      label="Cena netto [zł]"
                      :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
                      :disabled="correctionType === 'Ilość'"
                      type="number"
                      validate-on-blur
                      hide-details
                      placeholder="Wpisz wartość netto usługi [PLN]"
                      @input="recalculateGrossAndVatValues(index)"
                      @wheel="$event.target.blur()"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model.number="invoiceItem.quantity"
                      outlined
                      label="Ilość"
                      :rules="[
                        rules.number,
                        rules.greaterThan(-1),
                        rules.lowerThan(invoiceItem.maxQuantity + 1)
                      ]"
                      :disabled="(!invoiceItem.splittedTime && correctionType === 'Cena') || invoiceItem.quantity < 0"
                      :readonly="!!invoiceItem.splittedTime"
                      min="0"
                      :max="invoiceItem.maxQuantity || 0"
                      type="number"
                      validate-on-blur
                      placeholder="1"
                      hide-details
                      @input="handleQuantityChange(index, invoiceItem.quantity)"
                      @wheel="$event.target.blur()"
                    >
                      <template
                        #append
                        v-if="!!invoiceItem.splittedTime"
                      >
                        <div class="mt-n1">
                          <v-btn
                            icon
                            x-small
                            plain
                            height="16"
                            :disabled="getParentItem(invoiceItem.id).quantity === 1"
                            @click="setNewQuantity(invoiceItem, true)"
                          >
                            <v-icon big>
                              mdi-menu-up
                            </v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            x-small
                            plain
                            height="16"
                            :disabled="invoiceItem.quantity === 1"
                            @click="setNewQuantity(invoiceItem, false)"
                          >
                            <v-icon big>
                              mdi-menu-down
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="connected-orders mx-2 mb-4">
                    <span class="input-label">Przypięte zlecenia{{ invoiceItem.quantity === 0 ? ' - wszystkie zlecenia zostaną odpięte' : '' }}</span>
                    <v-row
                      v-for="(order, orderIdx) in invoiceItem.orders"
                      :key="order.id"
                      class="flex align-center justify-space-between pl-2"
                    >
                      <span> {{ order.refNumber }}</span>
                      <v-checkbox
                        v-model="order.active"
                        hide-details
                        class="mt-0"
                        :on-icon="invoiceItem.quantity === 0 ? 'mdi-close-box-outline' : 'mdi-checkbox-marked'"
                        :color="invoiceItem.quantity === 0 ? '#f86262' : '#2cb56b'"
                        :disabled="disableOrderCheckbox(order, invoiceItem)"
                        readonly
                        @click="setActiveStateOfOrder(index, orderIdx)"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="correctionType === 'Cena' && !invoiceItem.hasBeenMerged"
                cols="1"
              >
                <v-btn
                  v-if="invoiceItem.splittedTime"
                  icon
                  color="error"
                  title="Przywróć"
                  @click="restoreInvoiceItem(invoiceItem, index)"
                >
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  icon
                  color="primary"
                  title="Podziel"
                  :disabled="invoiceItem.quantity <= 1"
                  @click="splitInvoiceItem(invoiceItem, index)"
                >
                  <v-icon>mdi-call-split</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col
                cols="12"
                class="pb-0 pl-3 mb-6 mt-n3"
              >
                <Warning message="Wszystkie składowe dokumentu zostały odpięte od zleceń" />
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="12">
              <ForwardDocument
                :contact-emails="contactEmails"
                type="invoice"
                @update="updateContacts"
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="attachUnpaidInvoices"
                label="Załącz informację o zaległościach"
                hide-details
                class="mt-0"
              />
              <v-checkbox
                v-model="withDebrisInfo"
                label="Pokaż informacje o odpadach w zestawieniu"
                hide-details
                class="mt-0"
              />
              <v-checkbox
                v-model="withInvoiceReport"
                label="Pokaż informacje o kursach w zestawieniu"
                hide-details
                class="mt-0"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pl-3 mb-0 mt-n3"
              v-if="!contactEmails.length"
            >
              <Warning message="Dokument nie zostanie wysłany" />
            </v-col>
            <v-col
              v-if="hasCorrection"
              cols="12"
              class="pb-0 pl-3 mb-0 mt-n2"
            >
              <Warning message="Dokument posiada już korektę" />
            </v-col>
          </v-row>
        </InvoicePreview>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover mr-4"
        :loading="isProcessing"
        @click="previewCorrection({ persistInitialInvoiceItems: true })"
        :disabled="!dataHasBeenChanged"
      >
        Odśwież dane
      </v-btn>
      <v-btn
        color="primary"
        class="base-hover"
        type="submit"
        form="correctInvoiceForm"
        :loading="isProcessing"
        :disabled="!dataHasBeenChanged"
      >
        Wystaw korektę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import InvoicePreview from './Partials/InvoicePreview'
import ForwardDocument from '../../components/Forms/Partials/ForwardDocument'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import webSocketMixin from '../../mixins/webSocketMixin'
import rules from '../../utils/validators'
import { getFieldMask, parseAsBasicUnit, getGrossValue, dateStringFormat } from '../../utils'
import { mapState, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import attributes from '../../const/datePickerAttrributes'
import { DateTime, Interval } from 'luxon'
import api from '../../api/v1'
import { Invoice } from '../../models'

export default {
  components: {
    DialogFormWrapper,
    InvoicePreview,
    ForwardDocument,
  },
  mixins: [afterFormSubmitted, webSocketMixin],
  data: () => ({
    attributes,
    availableInvoiceNumbers: [],
    correctionTypes: ['Dane', 'Ilość', 'Cena'],
    correctionType: 'Dane',
    invoiceItems: [],
    initialInvoiceItems: [],
    invoiceData: {},
    initialInvoiceData: {},
    isPreviewMode: false,
    preview: {},
    contactEmails: [],
    attachUnpaidInvoices: false,
    withDebrisInfo: false,
    withInvoiceReport: false,
    rules,
    showSaleDatePicker: false,
    showIssueDatePicker: false,
    showDueDatePicker: false,
    latestInvoice: {},
    isCustomServices: false,
    paymentInterval: 0,
    invoiceHasBeenGenerated: false,
    forceInvoiceNumberRefreshKey: 0, // noticed bug with v-autocomplete, when we change value, it doesn't update
  }),
  channels: {
    InvoiceNumberRefreshChannel: {
      received(data) {
        if (this.invoiceHasBeenGenerated) return
        const { invoice_number: invoiceNumber, action_type: actionType } = data || {}

        switch (actionType) {
          case 'delete':
            this.refreshAvailableInvoiceNumbers()
            break
          case 'create':
            this.removeInvoiceNumberFromAvailableNumbers(invoiceNumber)
            break
          default:
            break
        }
      }
    },
  },
  computed: {
    ...mapState({
      isProcessing: state => state.invoice.isProcessing,
      invoice: state => {
        const item = state.layout.dialog.item
        // Invoice data can be passed from single invoice view - already transformed
        // or from invoices list - raw flat data
        return item.fromModel ? item : new Invoice(item)
      },
      clientContacts: state => state.client.entity?.contacts,
      department: state => state.core.department,
    }),
    clientContactsWithEmail() {
      return this.clientContacts?.filter(contact => !!contact.email) || []
    },
    hasInvoiceDataAlreadyBeenChanged() {
      return this.invoice.latestInvoice.correctionType === 'Dane'
    },
    hasCorrection() {
      return this.invoice.id !== this.invoice.latestInvoice.id
    },
    isCorrectionTypeWithInvoiceItems() {
      return ['Ilość', 'Cena'].includes(this.correctionType)
    },
    paymentMinDate() {
      return this.invoiceData?.issueDate || DateTime.utc().toISODate()
    },
    paymentDueDaysString() {
      const { paymentDueDate, issueDate } = this.invoiceData
      const date = this.formatDate(paymentDueDate)
      const intervalLength = this.getIntervalBetweenDates(issueDate, paymentDueDate)
      const suffix = intervalLength === 1 ? 'dzień' : 'dni'
      return `${date} - ${intervalLength} ${suffix}`
    },
    dataHasBeenChanged() {
      return JSON.stringify(this.invoiceData) !== JSON.stringify(this.initialInvoiceData) || JSON.stringify(this.invoiceItems) !== JSON.stringify(this.initialInvoiceItems)
    },

  },
  created() {
    this.subscribeSocket('InvoiceNumberRefreshChannel', {
      departmentId: this.department.id,
    })
    const clientId = this.invoice?.orders?.[0].client?.id || this.invoice.clientId
    if (clientId) {
      this.getClient(clientId)
    }
    this.getRawInvoiceData()
  },
  beforeDestroy() {
    this.unsubscribeSocket('InvoiceNumberRefreshChannel')
  },
  methods: {
    ...mapActions({
      correctInvoice: 'invoice/correctInvoice',
      getCorrectionPreview: 'invoice/getCorrectionPreview',
      getClient: 'client/getSingleClient',
      showSnackbar: 'snackbar/showSnackbar',
    }),
    updateContacts(contactEmails) {
      this.contactEmails = contactEmails
    },
    getParentItem(id) {
      return this.invoiceItems.find(item => item.id === id && !item.splittedTime)
    },
    setNewQuantity(newItem, add) {
      // When quantity is changed it should change active state of orders
      // parentItem - split origin item
      // newItem - new item created by splitting parentItem

      const parentInvoiceItem = this.getParentItem(newItem.id)

      const changeItemActiveOrders = (item) => {
        const quantityOfActiveOrders = item.quantity

        // Check if there is need to set active state for more orders or less
        if (quantityOfActiveOrders > item.orders.filter(order => order.active).length) {
          const lastNotActiveOrder = item.orders.findLast(order => {
            const isNotActive = !order.active
            const orderIsNotActiveInAlreadySplittedItem = !this.invoiceItems.some(item => {
              const checkedOrder = item.orders.find(o => o.id === order.id)
              return !!item.splittedTime && !!checkedOrder?.active
            })
            return isNotActive && orderIsNotActiveInAlreadySplittedItem
          })
          // 1. Set active to true for last not active order in changed invoice item
          lastNotActiveOrder.active = true
          // 2. False active state for this order in parent invoice item
          parentInvoiceItem.orders.find(order => order.id === lastNotActiveOrder.id).active = false
        } else {
          const lastActiveOrder = item.orders.find(order => order.active)
          // 1. Set active to false for last active order in changed invoice item
          lastActiveOrder.active = false
          // 2. True active state for this order in parent invoice item
          parentInvoiceItem.orders.findLast(order => order.id === lastActiveOrder.id).active = true
        }
      }

      // Change quantity of parent item and item
      if (add) {
        parentInvoiceItem.quantity -= 1
        parentInvoiceItem.maxQuantity -= 1
        newItem.quantity += 1
        newItem.maxQuantity += 1
      } else {
        parentInvoiceItem.quantity += 1
        parentInvoiceItem.maxQuantity += 1
        newItem.quantity -= 1
        newItem.maxQuantity -= 1
      }
      if (newItem.quantity !== newItem.orders.filter(order => order.active).length) changeItemActiveOrders(newItem)
    },
    splitInvoiceItem(invoiceItem, index) {
      const newItems = [
        { ...invoiceItem, new: true, quantity: invoiceItem.quantity - 1, maxQuantity: invoiceItem.quantity - 1 },
        cloneDeep({ ...invoiceItem, new: true, quantity: 1, maxQuantity: 1, splittedTime: +new Date() }),
      ].map((item, newItemsIndex, array) => {
        return {
          ...item,
          orders: item.orders.map((order, index) => {
            const newIndex = array[newItemsIndex - 1]?.quantity - 1
            const isActive = newItemsIndex === 0 ? item.quantity > index : item.quantity === index - newIndex
            return { ...order, active: isActive }
          })
        }
      })
      this.invoiceItems.splice(index, 1, ...newItems)
    },

    restoreInvoiceItem(invoiceItem, index) {
      const { id } = invoiceItem
      this.invoiceItems.splice(index, 1)
      const originIndex = this.invoiceItems.findIndex(item => item.id === id && !item.splittedTime)
      const splittedItems = this.invoiceItems.filter(item => item.id === id && item.splittedTime)
      const originalItem = this.initialInvoiceItems.find(item => item.id === id)
      if (!splittedItems.length) {
        this.invoiceItems[originIndex].new = false
      }
      const splittedItemsQuantity = splittedItems.reduce((acc, item) => acc + item.quantity, 0)
      // how many elements are in splitted items (quantity)
      const restQuantity = originalItem.quantity - splittedItemsQuantity
      // difference between base item and splitted ones
      if (restQuantity > 0) {
        this.invoiceItems[originIndex].quantity = restQuantity
        this.invoiceItems[originIndex].maxQuantity = restQuantity
        this.invoiceItems[originIndex].orders = this.invoiceItems[originIndex].orders.map((order, index) => ({ ...order, active: restQuantity > index }))
      }
    },

    getRawInvoiceData() {
      const { invoiceItems, buyerName, city, nip, address, postalCode, notes, saleDate, issueDate, paymentDueDate } = cloneDeep(this.invoice.latestInvoice)
      const { customServices } = cloneDeep(this.invoice)

      const items = invoiceItems.map(item => {
        const { netValue, ...rest } = item
        return {
          netValue: netValue / 100,
          ...rest
        }
      })
      const todayDate = DateTime.utc().toISODate()
      this.paymentInterval = this.getIntervalBetweenDates(issueDate, paymentDueDate)
      const correctionPaymentDueDate = DateTime.utc().plus({ days: this.paymentInterval }).toISODate()
      // we check payment interval from latest invoice and set payment due date for correction
      this.initialInvoiceData = { buyerName, city, nip, address, postalCode, notes: notes || '', saleDate: todayDate, issueDate: todayDate, paymentDueDate: correctionPaymentDueDate }
      this.invoiceData = { buyerName, city, nip, address, postalCode, notes: notes || '', saleDate: todayDate, issueDate: todayDate, paymentDueDate: correctionPaymentDueDate }
      this.isCustomServices = customServices || false
      this.invoiceItems = [...items]
      this.latestInvoice = { saleDate, issueDate, paymentDueDate }
      this.previewCorrection()
    },

    getFieldMask,
    getIntervalBetweenDates(startDate, endDate) {
      return new Interval({
        start: DateTime.fromISO(startDate),
        end: DateTime.fromISO(endDate)
      }).length('days')
    },
    removeOrdersDuplicates(array) {
      // removes duplicates from array of objects
      // active property has priority
      const uniqueObjects = {}

      array.forEach(item => {
        if (!uniqueObjects[item.id] || item.active) {
          uniqueObjects[item.id] = item
        }
      })
      return Object.values(uniqueObjects)
    },
    mergeObjectsByNetValue (arr) {
      // merges objects with the same netValue
      // orders are concatenated without duplicates
      // quantity is summed
      const merged = {}
      arr.forEach(obj => {
        if (merged[obj.netValue]) {
          merged[obj.netValue].orders = this.removeOrdersDuplicates(merged[obj.netValue].orders.concat(obj.orders))
          merged[obj.netValue].quantity += obj.quantity
        } else {
          merged[obj.netValue] = { ...obj }
        }
      })
      return Object.values(merged)
    },

    collectParams({ firstPreview = false } = {}) {
      let params = {
        correctionType: this.correctionType,
        contactEmails: this.contactEmails,
        attachUnpaidInvoices: this.attachUnpaidInvoices,
        withDebrisInfo: this.withDebrisInfo,
        withInvoiceReport: this.withInvoiceReport,
        saleDate: this.invoiceData.saleDate,
        issueDate: this.invoiceData.issueDate,
        paymentDueDate: this.invoiceData.paymentDueDate,
        isCustomServices: this.isCustomServices,
        invoiceNumber: this.invoiceData.invoiceNumber,
      }
      if (this.correctionType === 'Dane') {
        const { invoiceNumber, ...invoiceData } = this.invoiceData
        params = {
          ...params,
          ...invoiceData
        }
      } else if (this.correctionType === 'Ilość') {
        let invoiceItems
        if (this.invoiceItems.length) {
          invoiceItems = this.invoiceItems.map(item => {
            const initialInvoiceItem = this.initialInvoiceItems?.find(initialItem => initialItem.id === item.id) || null
            // if quantity of invoice item is set to "0" we have to send all orders to BE
            return {
              id: Number(item.id.split('-')[0]),
              quantity: item.quantity || 0,
              orderIds: item.quantity > 0 ? item.orders?.filter(order => order.active).map(order => order.id) : initialInvoiceItem?.orders?.map(order => order.id) || []
            }
          })
        } else {
          // if all invoice items are removed we have to send all invoice items with quantity 0 and orderIds from initialInvoiceItem
          invoiceItems = this.initialInvoiceItems.map(item => ({ id: Number(item.id.split('-')[0]), quantity: 0, orderIds: item.orders?.map(order => order.id) || [] }))
        }

        params.invoiceItems = invoiceItems
      } else if (this.correctionType === 'Cena') {
        // To prevent sending invoice items with the same netValue we have to merge them
        const mergedInvoiceItems = this.mergeObjectsByNetValue(this.invoiceItems)
        this.invoiceItems = mergedInvoiceItems
        const filterAndParseItems = (isNew) => mergedInvoiceItems
          .filter(item => Boolean(item.splittedTime) === isNew)
          .map(item => ({
            id: Number(item.id.split('-')[0]),
            quantity: item.quantity,
            netValue: parseAsBasicUnit(item.netValue),
            orderIds: item.orders?.filter(order => order.active).map(order => order.id) || []
          }))
        params.invoiceItems = filterAndParseItems(false)
        params.newInvoiceItems = filterAndParseItems(true)
        if (firstPreview) {
          params.firstPreview = firstPreview
        }
      }
      return params
    },

    previewCorrection({ useInitialInvoiceItems, persistInitialInvoiceItems } = { useInitialInvoiceItems: false, persistInitialInvoiceItems: false }) {
      // useInitialInvoiceItems - when we change correction type we want to use initial data, not the one from preview
      // persistInitialInvoiceItems - decides if initial data should be updated with data from preview

      this.isPreviewMode = !this.isPreviewMode
      if (useInitialInvoiceItems) this.invoiceItems = cloneDeep(this.initialInvoiceItems)

      // when previewing correction e.g. on correction type change,
      // preview data was the same as initial data, and we got an error from BE
      // we are using firstPreview param to avoid this situation
      const params = this.collectParams({ firstPreview: useInitialInvoiceItems })
      this.getCorrectionPreview({ params, id: this.invoice.id }).then(invoice => {
        // invoiceItems initially has ids, but has not orders
        // so we merge initial invoiceItems with those from response data
        const mergedInvoiceItems = []

        const splittedItemsHaveBeenMerged = this.invoiceItems.some(item => item.splittedTime)
        // After correction preview with splitted items set flag above to true and assign to every invoice item object to property 'hasBeenMerged'
        // it hides split/revert buttons - this actions are not allowed after merging splitted items
        // to split items again we have to set initial state of whole form by calling previewCorrection with useInitialInvoiceItems: true

        // <-- New logic for merging invoice items
        // now we are using ID do merge preview invoice items with initial invoice items (main invoice object)
        // if we split invoice item into two or more ( in correction type 'Cena')
        // we are adding quantity to ID to make it unique
        // when collecting params we are splitting ID by '-' and taking first part of it
        this.invoiceItems.filter(item => item.quantity > 0).forEach((item, index) => {
          const itemId = typeof item.id === 'number' ? item.id : Number(item.id.split('-')[0])
          const invoiceItem = invoice.invoiceItems.find(invoiceItem =>
            invoiceItem.id === itemId && invoiceItem.netValue === Number((item.netValue * 100).toFixed(2))
          )

          // After correction preview and items merge "splittedTime" property is unnecessary
          // and needs to be removed - it's used only for splitting invoice items
          delete item.splittedTime

          mergedInvoiceItems.push(cloneDeep({
            ...item,
            id: `${itemId}-${invoiceItem.quantity}-${index}`,
            quantity: invoiceItem.quantity,
            maxQuantity: invoiceItem.quantity,
            hasBeenMerged: splittedItemsHaveBeenMerged,
            orders: invoiceItem?.orders?.map((order) => {
              return { ...order, active: true }
            })
          }))
        })
        // -->

        if (!persistInitialInvoiceItems) this.initialInvoiceItems = cloneDeep(mergedInvoiceItems)
        this.invoiceItems = mergedInvoiceItems
        this.preview = { ...invoice, invoiceItems: mergedInvoiceItems }
        if (!this.initialInvoiceData.invoiceNumber) this.initialInvoiceData.invoiceNumber = invoice.invoiceNumber
        this.invoiceData.invoiceNumber = invoice.invoiceNumber
        this.availableInvoiceNumbers = invoice.availableInvoiceNumbers || []
      })
    },

    sumbitCorrectionForm() {
      if (this.$refs.correctInvoiceForm.validate()) {
        const params = this.collectParams()

        this.correctInvoice({ params, id: this.invoice.id }).then(() => {
          this.invoiceHasBeenGenerated = true
          this.afterFormSubmitted('Wystawiono korektę do dokumentu')
        })
      }
    },

    updateInvoiceNumber(invoiceNumber) {
      this.invoiceData.invoiceNumber = invoiceNumber
      this.preview.invoiceNumber = invoiceNumber
    },
    handleQuantityChange(index, quantity) {
      const invoiceItem = this.invoiceItems[index]
      const activeOrdersCount = invoiceItem.orders.filter(order => !!order?.active).length

      if (quantity === 0) {
        invoiceItem.orders.forEach(order => (order.active = true))
        return
      }

      // on quantity change manage active states of orders in invoice item
      if (quantity < activeOrdersCount) {
        invoiceItem.orders.forEach((order, orderIndex) => {
          if (orderIndex >= quantity) {
            order.active = false
          }
        })
      }

      if (quantity > activeOrdersCount) {
        invoiceItem.orders.forEach((order, orderIndex) => {
          if (orderIndex < quantity) {
            order.active = true
          }
        })
      }
    },
    setActiveStateOfOrder(invoiceItemIndex, orderIndex) {
      const selectedInvoiceItem = this.invoiceItems[invoiceItemIndex]
      const selectedOrder = selectedInvoiceItem.orders[orderIndex]

      // if quantity is 0 (in this scenario all orders have true active flag to be sent to BE) and we select an order we have to
      // set active flag to false for all other orders and - set quantity to 1 (next if statement)
      if (selectedInvoiceItem.quantity === 0) {
        selectedInvoiceItem.orders.forEach(order => {
          if (order.id !== selectedOrder.id) order.active = false
        })
      } else {
        selectedOrder.active = !selectedOrder.active
      }

      // if correction type is 'quantity' unselecting order should change quantity
      if (this.correctionType === 'Ilość') {
        const activeOrdersCount = selectedInvoiceItem.orders.filter(order => !!order.active).length

        if (activeOrdersCount > selectedInvoiceItem.quantity) {
          selectedInvoiceItem.quantity += 1
        }

        if (activeOrdersCount < selectedInvoiceItem.quantity) {
          selectedInvoiceItem.quantity -= 1
        }

        if (selectedInvoiceItem.quantity === 0) { // if quantity is set to 0 we have to select all orders to be sent to BE
          selectedInvoiceItem.orders.forEach(order => (order.active = true))
          return
        }
      }
      if (this.correctionType === 'Cena') {
        const hasActiveOrder = (item) => {
          return item.orders?.some(order => order.id === selectedOrder.id && order.active === true) || false
        }

        const getIndexesWithActiveOrders = () => {
          const indexes = []
          this.invoiceItems.filter((_, index) => index !== invoiceItemIndex).forEach((item, index) => {
            if (hasActiveOrder(item)) {
              indexes.push(index)
            }
          })
          return indexes
        }

        const indexesWithActiveOrders = getIndexesWithActiveOrders()

        indexesWithActiveOrders.forEach(index => {
          this.invoiceItems[index].orders.forEach(order => {
            if (order.id === selectedOrder.id) {
              order.active = false
            }
          })
        })
      }
    },
    disableOrderCheckbox(order, invoiceItem) {
      return (!order.active && invoiceItem.orders.filter(order => order.active).length === invoiceItem.maxQuantity) || (invoiceItem.orders.length === invoiceItem.maxQuantity && this.correctionType === 'Cena')
    },
    formatDate(date) {
      return date ? dateStringFormat(date) : ''
    },
    recalculateGrossAndVatValues(index) {
      const item = this.invoiceItems[index]
      item.grossValue = getGrossValue(item.netValue, item.vatPercentage) * 100
      item.vatValue = item.grossValue - item.netValue * 100
    },
    previewCorrectionOnIssueDateChange() {
      this.showIssueDatePicker = false
      const [year, month, day] = this.invoiceData.issueDate.split('-').map(Number)
      const parsedDate = DateTime.fromObject({ day, month, year })
      this.invoiceData.paymentDueDate = parsedDate.plus({ days: this.paymentInterval }).toISODate()
      this.previewCorrection()
    },
    refreshAvailableInvoiceNumbers() {
      // if chosen invoice number is not available, change it to first available
      const params = {
        mainInvoiceId: this.invoice.id,
        issueDate: this.invoiceData.issueDate,
        isCorrection: true,
      }
      api.getAvailableInvoiceNumbers(params).then(({ data }) => {
        this.availableInvoiceNumbers = data.invoiceNumbers

        if (!this.availableInvoiceNumbers.includes(this.invoiceData.invoiceNumber)) {
          // if chosen invoice number is not available, change it to first available
          this.invoiceData.invoiceNumber = this.availableInvoiceNumbers.at(-1)
          this.preview.invoiceNumber = this.availableInvoiceNumbers.at(-1)
          this.showSnackbar({
            type: 'success',
            message: ['Zaktualizowano zaktualizowano listę dostępnych numerów faktury oraz zmieniono wybrany numer faktury']
          })
        } else {
          // else just show success message
          this.showSnackbar({
            type: 'success',
            message: ['Zaktualizowano zaktualizowano listę dostępnych numerów faktury']
          })
        }
        this.forceInvoiceNumberRefreshKey += 1
      })
    },
    removeInvoiceNumberFromAvailableNumbers(invoiceNumber) {
      // get last available invoice number before removing
      const lastAvailableInvoiceNumber = [...this.availableInvoiceNumbers].at(-1)
      // WS callback for removing invoice number from available numbers - WS sends just used invoice number
      this.availableInvoiceNumbers = this.availableInvoiceNumbers.filter(number => number !== invoiceNumber)

      if (this.invoiceData.invoiceNumber !== invoiceNumber) return
      // if there is no available invoice numbers currently in the state,
      // or last available invoice number has been removed, refresh available invoice numbers to get newest one
      if (!this.availableInvoiceNumbers.length || lastAvailableInvoiceNumber === invoiceNumber) {
        this.refreshAvailableInvoiceNumbers()
        return
      }
      // if chosen invoice number is not available, change it to first available
      this.invoiceData.invoiceNumber = this.availableInvoiceNumbers.at(-1)
      this.preview.invoiceNumber = this.availableInvoiceNumbers.at(-1)
      this.showSnackbar({ type: 'info', message: ['Zmieniono numer faktury - poprzedni został wykorzystany'] })
      this.forceInvoiceNumberRefreshKey += 1
    },
  }
}
</script>

<style lang="scss" scoped>
.invoice-item-name::v-deep input {
  color: black !important;
}

.connected-orders {
  background-color: #2cb56b1c;
  border-radius: 4px;
  padding: 8px 0px 8px 12px !important;
}
</style>
